.wrapper {
  height: 100%;
  padding: 8px;
}

.localVideo {
  position: relative;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #454B5C;
  margin-bottom: 16px;
}

.teacherVideo {
  position: relative;
  width: 100%;
  margin: 0 auto 8px;

  .localVideoHide div video {
    display: none;
  }
  &.localVideoBG{
    background-color:black;
  }

  .fullVideoMessage {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &.fullVideoMessageHide {
      display: none;
    }
    img {
      position: absolute;
      width: 50%;
      top: 30%;
    }
  }
}

.remoteVideoContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  left: -50%;
  z-index: 10000;
}

.chatContent {
  display: flex;
  flex-flow: column;
  height: 100%;

  .chatRoomWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 40%;
    flex: 1 1 auto;
  }
}
