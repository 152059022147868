.studentList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-120%) translateY(-50%);
  width: 320px;
  height: 480px;
  max-width: 100%;
  max-height: 100%;
  background-color: #232323;
  border-radius: 12px;
  padding: 16px;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #454B5C;
    .close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

  }

  .content {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
			width: 1px;
			background-color: #232323;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #232323;
		}
    .student{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0px 8px 8px;

      .name {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .controls {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      &.active {
        color: #EC7963;
        svg path{
          fill: #EC7963;
        }
      }
    }
  }

  .footer {
    display: flex;
    .info {
      display: flex;
      align-items: center;

      .label {
        color: #91949E;
      }

      & + .info {
        margin-left: 8px;
      }
    }
  }
}