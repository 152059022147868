.wrapper {
  height: 100%;
  padding: 8px;
}

.localVideo {
  position: relative;
  width: 100%;
  height: fit-content;
  margin-bottom: 8px;

  &.localVideoHide div video {
    display: none;
  }
  &.localVideoBG{
    background-color:black;
  }
  .fullVideoBtnHide {
    display: none;
  }

  button {
    width: 25%;
    height: 25px;
  }
  .offFullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    &.offFullVideoBtnHide {
      display: none;
    }
    img {
      position: absolute;
      width: 50%;
      top: 30%;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
  .fullVideoBtn {
    cursor: pointer;
    position: absolute;
    width: 10%;
    margin: 10px;
    right: 0px;
    top: 0px;
    img {
      position: absolute;
    }
    img:last-child:hover {
      opacity: 0;
    }
  }
}

.remoteVideoContainer {
  // width: 50%;
  max-height: 100%;
  position: absolute;
  right: calc(100% + 40px);
}

.chatContent {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;

  .controls{
    height: 48px;
  }

  .chatRoomWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 50%;
    flex: 1 1 auto;
  }
  .teacherVideo {
    height: auto;
  }
  .studentListbutton {
    width: 100%;
    height: 48px;
    background: #383A42;
    border-radius: 8px;
    text-align: center;
    padding: 12px 0px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}

.listContent {
  width: 320px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
  overflow-y: hidden;
  background: #232323;
  border-radius: 12px;
}

.closeDialog {
  width: 30%;
  padding: 5px 0;
  text-align: center;
  margin: 0 auto;
  background: #00828f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.listDialogBottom{
  width: 100%;
  display: flex;
  .bottomText{
    display: inline-grid;
    align-items: center;
    width: 70%;
  }
  .closeListDialog {
    width: 30%;
    padding: 5px 0;
    text-align: center;
    margin: 0 auto;
    background: #00828f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
}

